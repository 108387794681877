import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

const realFade: any = createSimpleTransition('realfade');

Vue.use(Vuetify);
Vue.component('realfade', realFade);

export default new Vuetify({
  theme: {
    themes: {
      light: {
				primary: '#1a1a1a',
				secondary: '#EB0A1E',
				accent: '#82B1FF',
				error: '#d1091b',
				success: '#1a1a1a',
      },
    },
  },
  icons: {
    iconfont: 'md',
    values: {
      'tb-arrow-down': 'tb-arrow-down',
			'tb-icon-unchecked': 'tb-icon-unchecked',
      'tb-icon-checked': 'tb-icon-checked',
      'tb-close': 'tb-close'
		}
  },
});
