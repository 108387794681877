





























import Vue from 'vue';
import { mapState } from 'vuex'
import LoginForm from '@/components/LoginForm.vue';
import Analytics from '@/mixins/analytics';
export default Vue.extend({
	name: 'LoginModal', 
	components: {
		LoginForm
	}, 
	props: {
		show: {
			type: Boolean
		}
	},
	methods: {
		handleCloseClick(): void {
			Analytics.sendEvent({
				"event":"buttonClick",
				"buttonName": "MyToyota Login – Close"
			});
			this.$store.commit('resetOpenModal');
		}, 
		handleLoginUpdate(): void {
			this.$emit('login');
			this.handleCloseClick();
		}
	}
});
