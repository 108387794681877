import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import CheckoutPage from '../views/CheckoutPage.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/purchase/newcars/dump',
    name: 'StoreDump',
    component: () => import('../views/StoreDump.vue')
  },
  {
    path: '/purchase/newcars/',
    name: 'CheckoutPage',
    component: () => import('../views/CheckoutPage.vue')
  },
  {
    path: '/purchase/newcars/enquire-tpr',
    name: 'FallbackPage',
    component: () => import('../views/FallbackPage.vue')
  },
  // {
  //   path: '/purchase/newcars/get-tpr',
  //   name: 'TprPage',
  //   component: () => import('../views/TprPage.vue')
  // },
  {
    path: '/purchase/newcars/adjust-tpr',
    name: 'TprAdjust',
    component: () => import('../views/TprAdjust.vue')
  },
  {
    path: '/purchase/newcars/trade-valuation',
    name: 'TradeInPage',
    component: () => import('../views/TradeInPage.vue')
  },
  // {
  //   path: '/purchase/newcars/finance-application',
  //   name: 'FinancePage',
  //   component: () => import('../views/FinancePage.vue')
  // },
  {
    path: '/purchase/newcars/summary',
    name: 'SummaryPage',
    component: () => import('../views/SummaryPage.vue')
  },
  {
    path: '/',
    name: 'RedirectPage',
    component: () => import('../views/RedirectPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes
})

export default router
