<template>
	<v-overlay 
		:opacity="1"
		:value="show"
    >
		<v-progress-circular
			indeterminate
			color="#fff"
		></v-progress-circular>
    </v-overlay>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'LoadingModal', 
	props: {
		show: Boolean
	}
})
</script>