





import Vue from 'vue';

export default Vue.extend({
	name: 'AlertBanner', 
	props: {
		message: String
	}
})
