import axios from 'axios';
import Helpers from '@/mixins/helpers';
import store from '@/store';

const api = (window as any).AppMode === 'app' ? 'https://app.toyotainventory.com.au/api/' : ((window as any).AppMode === 'tpr' ? 'https://tpr-toyotainventory-app-service.azurewebsites.net/api/' : 'https://stagingapp.toyotainventory.com.au/api/');

const apiOmni = (window as any).AppMode === 'app' ? 'https://storeapp.toyotainventory.com.au/' : ((window as any).AppMode === 'tpr' ? 'https://tpr-store-app-service.azurewebsites.net/' : 'https://stagingstoreapp.toyotainventory.com.au/');

const apiTpr = (window as any).AppMode === 'app' ? 'https://tprapp.azurewebsites.net/' : ((window as any).AppMode === 'tpr' ? 'https://tpr-app-service.azurewebsites.net/' : 'https://tprapp-stage.azurewebsites.net/');

const fslApi = 'https://fslclient.azurewebsites.net/';

const Api: any = {
    getFslURL: function(): string {
        return `${fslApi}FSLResult`;
    },
    getValueAddURL: function(dealerId: number, vehicleType: number){
        return `${api}ValueAdd?dealerID=${dealerId}&vehicleType=${vehicleType}`;
    },
    getVehicleImagesURL: function(m: string, produced: string){
        if (!m || !produced) return '';
        const p = produced.split('-').reverse().join('/');
        return `${api}InventoryImagev2/?id=${m}${'&plannedMonth=' + p}`;
    },
    getVehicleURL: function(materialCode: string, vehicleSpecificationID: string, batchNumber: string, queryDealerID: string): string {
        if (materialCode && queryDealerID) {
            let url: string = api +
                'VehicleInfo?' +
                'MaterialCode=' + materialCode +
                '&' +
                (vehicleSpecificationID ? 'VehicleSpecificationID=' + vehicleSpecificationID + '&' : '') +
                'StockDealerID=' + queryDealerID;
            if (batchNumber) url = url + '&BatchNumber=' + batchNumber;
            return url;
        }
        return '';
    },
    // returnVehiclesAvailableURL: function(queryDealerID: string): string {
    //     if (queryDealerID) {
    //         const url = `${api}StoreNavCategory/${queryDealerID}`;
    //         return url;
    //     }
    //     return '';
    // },
    getChangeCarURL: function(dealerID: string, variantCode: string, vehicleSpecificationID: string) {
        if (dealerID) {
            const url = `${api}InventoryLink/?dealerid=${dealerID}&variantcode=${variantCode}&vsid=${vehicleSpecificationID}`
            return url;
        }
        return '';
    },
    getFinanceCalculationURL: function(price: number, deposit: string, termInMonths: number, kmsPerYear: number, stockDealerID: string, vehicleSpecificationID: string, termOffset = 0, materialCode = '', produced: string, guid: string, orderType: number): string {
        if (price > 0 && termInMonths > 0 && kmsPerYear > 0 && stockDealerID && vehicleSpecificationID) {
            const url: string = api +
                'FinanceCalculation?' +
                'Price=' + price +
                '&' +
                'Deposit=' + deposit +
                '&' +
                'TermInMonths=' + termInMonths +
                '&' +
                'KmsPerYear=' + kmsPerYear +
                '&' +
                'StockDealerID=' + stockDealerID +
                '&' +
                'VehicleSpecificationID=' + vehicleSpecificationID +
                '&' +
                'TermOffSet=' + termOffset +
                '&' +
                'MaterialCode=' + materialCode +
                '&' +
                'Produced=' + produced +
                (guid ? '&Personalised=' + guid : '') + 
                '&LoanType=' + orderType;
            return url;
        }
        return '';
    },
    getUserInfoUrl: function(guest: string, auth: string, key: string): string {
        return apiTpr + `/tpr/v1/ephemeral/ephemeralData?guest=${guest}&auth=${auth}&key=${key}`;
    },
    getUserInfoResponse: function(data: any): any {
        let dob = data.dateOfBirth;
        let dobyear = '';
        let dobmonth = '';
        let dobday = '';
        if (data.dateOfBirth) {
            const dobArray = data.dateOfBirth.split('T');
            if (dobArray.length) {
                const dobArray2 = dobArray[0].split('-');
                if (dobArray2.length > 2) {
                    dobyear = dobArray2[0];
                    dobmonth = dobArray2[1];
                    dobday = dobArray2[2];
                }
                dob = dobArray[0];
            }
        }
        return {
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            dob: dob || '',
            dobday: dobday || '',
            dobmonth: dobmonth || '',
            dobyear: dobyear || '',
            phone: data.telephone || '',
            email: data.email || '',
            address: data.address || '',
            unitNo: data.unitNumber || '',
            streetNo: data.streetNumber || '',
            streetName: data.streetName || '',
            streetType: data.streetType || '',
            suburb: data.suburb || '',
            postcode: data.postcode || '',
            state: data.state || '',
            livingStatus: data.livingStatus || 0,
            driverLicenseNumber: data.driversLicenceNumber || '',

            // not in
            driverLicenseCountry: data.driversLicenceCountry || '',
            driverLicenseState: data.driversLicenceState || ''
        }
    },
    getFinanceResponse: function(response: any): any {
        return {
            isLoading: false,
            agencyFee: response.DAFincGST,
            apr: response.APR,
            comparisonRate: response.ComparisonRatePercentage,
            DAFincGST: response.DAFincGST,
            // disclaimer: response.InternalFinanceType === 'Indicative' ? '' : response.Disclaimer,
            disclaimer: response.Disclaimer,
            disclaimerKey: response.DisclaimerKey,
            establishmentFee: response.EstablishmentFee,
            financeDeposit: response.AdjustedDeposit,
            financeType: response.FinanceType,
            gfv: response.GFV,
            internalFinanceType: response.InternalFinanceType || '',
            maxFinanceDeposit: response.MaximumDeposit,
            monthlyAccountFee: response.MonthlyAccountAdministrationFee,
            monthlyPayment: response.MonthlyPaymentAmountWithCents,
            netAmountFinanced: response.NAF,
            ppsrFee: response.PPsrFee,
            totalAmountFinanced: response.TAF,
            weeklyPayment: response.PricePerWeek,
            financeHeading: response.FinanceHeading,
            cmpFrom: response.CmpFrom,
            cmpTo: response.CmpTo,
            creditScore: response.CreditScore,
            isTpr: response.IsTPR,
            rateExpiry: response.RateExpiry,
            tprDifference: response.TPRDifference
        }
    },
    getDealerInfoURL: function(dealerID: number): string {
        if (dealerID !== null){
            const url = api +
                'DealerInfo?' +
                'id=' + dealerID +
                '&' +
                'includeOpeningTimesForNewDepartment=true'
            return url;
        }
        return '';
    },
    getDealerAgentURL: function(dealerID: number): string {
        if (dealerID !== null){
            const url = api + 'DealerStoreInfo/' + dealerID;
            return url;
        }
        return '';
    },
    getTprPostURL: function(): string {
        return apiTpr + "tpr/v1/personalisedRate";
    },
    getTprLeadStartUrl: function(): string {
        return apiOmni + 'api/FinanceEnquiry';
    },
    getTprLeadEndUrl: function(): string {
        return apiOmni + 'api/FinanceConfiguration';
    },
    returnEnquirySourceUrl(dealerId: string): string {
        return api + 'DealerUtmTracking/' + dealerId;
    },
    // returnEmailGatePostURL: function() {
    //     return apiOmni + 'api/IncompleteOrder'
    // },
    returnPromoCodeURL: function(code: string, dealerID: number, materialCode: string, vehicleSpecificationID = '', batchNumber = '', guestEmail = ''){
        return apiOmni + 'api/PromoCode?' +
            'code=' + code +
            '&' +
            'dealerId=' + dealerID +
            '&' +
            'materialCode=' + materialCode +
            '&' +
            'vehicleSpecId=' + vehicleSpecificationID +
            '&' +
            'batchNumber=' + batchNumber +
            '&' +
            'guestEmail=' + guestEmail;
    },
    getFinanceLeadPostURL: function() {
        return apiOmni + 'api/FinanceEnquiry'
    },
    postLeadStart(callbackSuccess: Function, callbackFail: Function): void {
        if (
            // store.state.app.tprFormSubmitted &&
            (!store.state.tpr.leadId) && 
            !store.state.customer.hasSubmittedLead
        ){
            const data = this.getFullData();
            const url = this.getTprLeadStartUrl();
            Helpers.clog(data);
            axios.post(url, data).then((response: any) => {
                Helpers.clog(response);
                if (response.data.SaveSuccess){
                    if (callbackSuccess && typeof callbackSuccess == 'function'){
                        callbackSuccess(response);
                    }
                }
                else {
                    if (callbackFail && typeof callbackFail == 'function'){
                        callbackFail();
                    }
                }
            }).catch((error) => {
                Helpers.clog(error);
                if (callbackFail && typeof callbackFail == 'function'){
                    callbackFail();
                }
            });					
        }
    },
    postLeadEnd(): void {
        if (
            (store.state.tpr.leadId || store.state.app.financeLeadOrderNumber) &&
            !store.state.customer.hasSubmittedLead && 
            !store.state.app.isFinanceLoading
        ){
            const url = this.getTprLeadEndUrl();
            const data = this.getEndData();
            Helpers.clog(data);
            axios.post(url, data).then((response: any) => {
                Helpers.clog(response);
                if (response.data){
                    store.commit('setCustomer', {
                        hasSubmittedLead: true
                    });
                }
            }).catch((error) => {
                Helpers.clog(error);
            });
        }
    }, 
    getFullData(): any {
        const d = store.state.disclaimers.filter((d: any) => d.for == 'vehicle').map((d: any) => (d.key + ' ' + d.text)).join(' ');
        const r = {
            DealerName: store.state.dealer.dealerName,
            DealerBranch: store.state.customer.preferredDealership,
            LocationName: store.state.dealer.locationName,
            DealerID: store.state.dealer.dealerID,
            StockDealerID: store.state.dealer.stockDealerID,
            Title: store.state.customer.title,
            FirstName: store.state.customer.firstName,
            // PreferredName: store.state.customer.preferredName,
            // LastName: store.state.customer.lastName,
            // DateOfBirth: (store.state.customer.dob) ? store.state.customer.dob + 'T00:00:00' : '1900-01-01T00:00:00', 
            Telephone: store.state.customer.phone,
            Email: store.state.customer.email,
            // UnitNumber: store.state.customer.unitNo,
            // StreetNumber: store.state.customer.streetNo,
            // StreetName: store.state.customer.streetName,
            // StreetType: store.state.customer.streetType,
            // Suburb: store.state.customer.suburb,
            // Postcode: store.state.customer.postcode,
            // State: store.state.customer.state,
            // Address: ((store.state.customer.unitNo && !store.state.customer.unitNo.includes('Unit')) ? 'Unit ' : '') +
            // (store.state.customer.unitNo ? store.state.customer.unitNo + ' ' : '') +
            // (store.state.customer.streetNo ? store.state.customer.streetNo + ' ' : '') +
            // (store.state.customer.streetName ? store.state.customer.streetName + ' ' : '') +
            // (store.state.customer.streetType ? store.state.customer.streetType : ''),
            // LivingStatus: store.state.customer.livingStatus ? parseInt(store.state.customer.livingStatus) : 0,
            // DriverLicenseNumber: store.state.customer.licenseNumber,
            VehicleSpecificationID: store.state.vehicle.vehicleSpecificationID,
            Materialcode: store.state.vehicle.materialCode,
            VIN: store.state.vehicle.vin,
            Backlink: store.state.app.referrer,
            DriveawayPrice: store.state.vehicle.price,
            Deposit: store.state.app.paymentMethod === 'finance' ? store.state.app.financeDeposit : store.state.app.cashDeposit,
            PrivacyAccepted: true,
            // LicenseOrigin: store.state.customer.licenseOrigin,
            // LicenseState: store.state.customer.licenseState == 'Overseas' ? '' : store.state.customer.licenseState,
            // OtherProperty: store.state.customer.otherProperty,
            EnquiryType: store.state.customer.orderType == 1 && store.state.finance.isTpr ? 2 : 1, // if business or not tpr, enquiry type is 1
            CaseID: store.state.tpr.pegaCase || 0,
            EnquirySource: store.state.app.enquirySource.id,
            Vehicle: {
                Materialcode: store.state.vehicle.materialCode,
                VIN: store.state.vehicle.vin,
                BatchNumber: store.state.vehicle.batchNumber,
                InStock: false,
                DriveawayPrice: store.state.vehicle.price,
                Model: store.state.vehicle.model,
                Grade: store.state.vehicle.grade,
                FullDescription: store.state.vehicle.fullDescription,
                Transmission: store.state.vehicle.transmission,
                Paint: store.state.vehicle.paintName,
                Trim: store.state.vehicle.trimName,
                FactoryOptionDescription: store.state.vehicle.inclOption,
                Disclaimer: d
            }, 
            Finance: {
                ComparisonRate: store.state.finance.comparisonRate,
                FinanceDeposit: store.state.finance.financeDeposit,
                LoanTermInYears: store.state.app.loanTerm,
                WeeklyPayment: store.state.finance.weeklyPayment
            }
            // Finance: {
            //     AgencyFee: store.state.finance.agencyFee,
            //     Apr: store.state.finance.apr,
            //     ComparisonRate: store.state.finance.comparisonRate,
            //     Disclaimer: store.state.finance.disclaimer,
            //     EstablishmentFee: store.state.finance.establishmentFee,
            //     FinanceDeposit: store.state.finance.financeDeposit,
            //     FinanceType: store.state.finance.financeType,
            //     Gfv: store.state.finance.gfv,
            //     InternalFinanceType: store.state.finance.internalFinanceType,
            //     LoanTermInYears: store.state.app.loanTerm,
            //     KmsPerYear: store.state.app.kmsPerYear,
            //     MonthlyAccountFee: store.state.finance.monthlyAccountFee,
            //     MonthlyPayment: store.state.finance.monthlyPayment,
            //     NetAmountFinanced: store.state.finance.netAmountFinanced,
            //     PpsrFee: store.state.finance.ppsrFee,
            //     TotalAmountFinanced: store.state.finance.totalAmountFinanced,
            //     WeeklyPayment: store.state.finance.weeklyPayment,
            //     IsTpr: store.state.finance.isTpr
            // }
        };
        return r;
    }, 
    getEndData(): any {
        return {
            FinanceEnquiryNumber: store.state.tpr.leadId || store.state.app.financeLeadOrderNumber,
            DealerID: store.state.dealer.dealerID,
            StockDealerID: store.state.dealer.stockDealerID,
            CaseID: store.state.tpr.pegaCase || 0,
            Finance: {
                ComparisonRate: store.state.finance.comparisonRate,
                FinanceDeposit: store.state.finance.financeDeposit,
                LoanTermInYears: store.state.app.loanTerm,
                WeeklyPayment: store.state.finance.weeklyPayment
            }
        };
        // return {
        //     FinanceEnquiryNumber: store.state.tpr.leadId || store.state.app.financeLeadOrderNumber,
        //     DealerID: store.state.dealer.dealerID,
        //     StockDealerID: store.state.dealer.stockDealerID,
        //     CaseID: store.state.tpr.pegaCase || 0,
        //     Finance: {
        //         AgencyFee: store.state.finance.agencyFee,
        //         Apr: store.state.finance.apr,
        //         ComparisonRate: store.state.finance.comparisonRate,
        //         Disclaimer: store.state.finance.disclaimer,
        //         EstablishmentFee: store.state.finance.establishmentFee,
        //         FinanceDeposit: store.state.finance.financeDeposit,
        //         FinanceType: store.state.finance.financeType,
        //         Gfv: store.state.finance.gfv,
        //         InternalFinanceType: store.state.finance.internalFinanceType,
        //         LoanTermInYears: store.state.app.loanTerm,
        //         KmsPerYear: store.state.app.kmsPerYear,
        //         MonthlyAccountFee: store.state.finance.monthlyAccountFee,
        //         MonthlyPayment: store.state.finance.monthlyPayment,
        //         NetAmountFinanced: store.state.finance.netAmountFinanced,
        //         TotalAmountFinanced: store.state.finance.totalAmountFinanced,
        //         PpsrFee: store.state.finance.ppsrFee,
        //         WeeklyPayment: store.state.finance.weeklyPayment,
        //         IsTpr: store.state.finance.isTpr
        //     }
        // };
    },

    returnSensisURL: function(searchTerm: string): any {
        if (searchTerm !== null){
            const url = apiOmni +
                'api/sensisproxy?path=' +
                '%2Faddress%2F%3Fcountry%3DAU%26formatted_address%3D' +
                encodeURIComponent(searchTerm);
            return url;
        }
        return false;
    },
    returnSensisResultURL: function(searchResultId: string): any {
        if (searchResultId !== null) {
            const url = apiOmni +
                'api/sensisproxy?path=' +
                '%2Faddress%2F' +
                searchResultId;
            return url;
        }
        return false;
    },
    returnPaymentPostURL: function() {
        return apiOmni + 'api/Order'
    },
    returnEnquiryPostURL: function() {
        return apiOmni + 'api/OrderEnquiry'
    },
    // returnLoadOrderURL: function(dealerId, guid, hash) {
    //     if (dealerId && guid && hash) {
    //         const url = apiOmni + 'api/Order?' +
    //             'DealerID=' + encodeURIComponent(dealerId) +
    //             '&' +
    //             'OrderGuid=' + encodeURIComponent(guid) +
    //             '&' +
    //             'OrderHash=' + encodeURIComponent(hash)
    //         return url
    //     }
    //     return false
    // },
    returnFinanceAppPostURL: function() {
        const url = apiOmni + 'api/OrderFinance'
        return url
    },
    returnTradeInPostURL: function() {
        const url = apiOmni + 'api/TradeIn'
        return url
    },
    getReferenceCodeStateURL: function(id: number){
        return api + `PromoCode?id=${id}`;
    },
    returnGuidCheckUrl(guid: string) {
        return apiTpr + 'tpr/v1/expiry?guest=' + guid;
    }, 
    returnLogActionUrl(): string {
        // return 'https://stagingstoreapp.toyotainventory.com.au/reservation/v1/session';
		return apiOmni + 'reservation/v1/session';
    },
    returnRefreshSessionUrl(): string {
        return apiOmni + 'myToyota/v1/refresh';
    },
    processFinanceResponse(response: any) {
        return {
            agencyFee: response.DAFincGST,
            apr: response.APR,
            comparisonRate: response.ComparisonRatePercentage,
            DAFincGST: response.DAFincGST,
            depositTotal: response.AdjustedDeposit,
            disclaimer: response.InternalFinanceType === 'Indicative' && !response.IsTPR ? '' : response.Disclaimer,
            disclaimerKey: response.DisclaimerKey,
            establishmentFee: response.EstablishmentFee,
            financeDeposit: response.AdjustedDeposit,
            financeType: response.FinanceType,
            gfv: response.GFV,
            internalFinanceType: response.InternalFinanceType || '',
            maxFinanceDeposit: response.MaximumDeposit,
            monthlyAccountFee: response.MonthlyAccountAdministrationFee,
            monthlyPayment: response.MonthlyPaymentAmountWithCents,
            netAmountFinanced: response.NAF,
            ppsrFee: response.PPsrFee,
            totalAmountFinanced: response.TAF,
            weeklyPayment: response.PricePerWeek,
            financeHeading: response.FinanceHeading,
            cmpFrom: response.CmpFrom,
            cmpTo: response.CmpTo,
            creditScore: response.CreditScore,
            isTpr: response.IsTPR,
            tprDifference: response.TPRDifference,
            rateExpiry: response.RateExpiry
        }
    }, 
    getUpdatedfinance(vm: any, success: Function, fail: Function): void {
        vm.$store.commit('setFinance', {
            isLoading: true
        });
        const financeCalculationURL = this.getFinanceCalculationURL(
            vm.$store.getters.vehicleFinalPrice, 
            vm.app.financeDeposit, 
            vm.app.loanTerm * 12, 
            vm.app.kmsPerYear, 
            vm.dealer.queryDealerID, 
            vm.vehicle.vehicleSpecificationID, 
            vm.vehicle.termOffset, 
            vm.vehicle.materialCode, 
            vm.vehicle.produced, 
            (vm.customer.orderType == 1 ? vm.tpr.guestRateIdentifier : ''), 
            vm.customer.orderType
        );
        axios.get(financeCalculationURL).then((response: any) => {
            Helpers.clog(financeCalculationURL);
            Helpers.clog(response.data);
            if (response.data.WasFinanced){ 
                const financeData = this.getFinanceResponse(response.data);					
                vm.$store.commit('setFinance', financeData);
                vm.$store.commit('setApp', {
                    financeDeposit: financeData.financeDeposit, 
                    maxFinanceDeposit: financeData.maxFinanceDeposit
                });

                vm.$store.dispatch('removeDisclaimer', 'finance');
                vm.$store.commit('addDisclaimer', {
                    type: "finance", 
                    for: 'finance',
                    key: '',
                    text: financeData.disclaimer
                });
                if (success && typeof success == 'function'){
                    success(financeData);
                }
            }
            else {
                vm.$store.commit('setApp', {
                    paymentMethod: 'cash', 
                    isFinanceAvailable: false
                });
                if (fail && typeof fail == 'function'){
                    fail();
                }
            }
            vm.$store.commit('setFinance', {
                isLoading: false
            });
        }).catch(response => {
            Helpers.clog(response);
            vm.$store.commit('setApp', {
              paymentMethod: 'cash', 
              isFinanceAvailable: false
            });
            if (fail && typeof fail == 'function'){
                fail();
            }
            vm.$store.commit('setFinance', {
                isLoading: false
            });
        });
    }
};

export default Api;
