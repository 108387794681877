import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app: {
      isFslEnabled: false,
      disableDisclaimer: false,
      showValueAdd: true,
      paid: false,
      financeLeadOrderNumber: '',
      financeType: '',
      isFinanceAvailable: false,
      isFinanceLoading: false,
      referenceEnabled: false,
      hasActiveReferenceCodes: false,
      webkiosk: false, 
			loginLogged: false, 
      step: 0, 
      changeCarUrl: '',
      referrer: '',
      cashDeposit: 100, 
      cashDepositBackup: 100, 
      financeDeposit: 0, 
      financeDepositBackup: 0, 
      financePayToday: 0,
      loanTerm: 1,
      loanTermBackup: 1,
      kmsPerYear: 10000, 
      kmsPerYearBackup: 10000, 
			myToyotaLink: 'https://www.toyota.com.au/mytoyota-home',
      orderNumber: '',
      orderGuid: '', 
      orderHash: '',
			session: '',
      myToyotaId: '', 
      hasCompletedWithMyToyotaId: false,
      sessionTimeout: 600,
      paymentMethod: 'finance',
      tprSource: '', 
      tprFormSubmitted: false,
			enquirySource: {
				id: null, 
				suburb: null, 
				value: null
			}
    },
    fsl: null,
    valueAdd: {
      hasSentAnalytics: false,
      hasRetrievedData: false,
      data: []
    },
    vehicle: {
      materialCode: '',
      specialUniqueName: '',
      vehicleSpecificationID: 0,
      variantCode: '',
      batchNumber: '',
      image: '',
      images360: [],
      franchise: 'Toyota',
      model: '',
      condition: 'Brand New',
      vin: '',
      grade: '',
      gradeLevelDescription: '',
      type: 'New Virtual',
      
      special: '',
      specialItem: null,
      specialExtraInfo: '',
      hasNationalSpecial: false,

      price: 0,
      transmission: '',
      fuel: '',
      bodyColour: '',
      interiorColour: '',
      inclOption: '',
      canBeFinanced: false,
      financeFailureReason: '',
      fullDescription: '',
      strippedFullDescription: '',
      initialFinanceSetting: {
        termInYears: null
      },
      availableFinanceSetting: [],
      paintName: '',
      trimName: '',
      disclaimer: '',
      disclaimerKey: '',
      combinedVehicleDisclaimers: '',
      vehicleType: null
    },
    dealer: {
      locationName: '',
      dealerName: '',
      dealerID: 0,
      stockDealerID: 0,
      queryDealerID: 0,
      dealerSites: [],
      lmct: '',
      region: '',
      state: '',
      nviUrl: '',
      website: '',
      depositMinimum: 0,
      cashDepositMaximum: 2000,
      callbackDays: [],
      openingHours: [],
      vehicleReturnGuarantee: false,
      privacyPolicy: '',
      storeWhyBuy: ''
    },
    agent: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      photoUrl: ''
    },
    finance: {
      isLoading: false,
      agencyFee: 0,
      apr: 0,
      comparisonRate: 0,
      disclaimer: 0,
      disclaimerKey: 0,
      establishmentFee: 0,
      financeDeposit: 0,
      financeType: 0,
      gfv: 0,
      internalFinanceType: '',
      maxFinanceDeposit: 0,
      monthlyAccountFee: 0,
      monthlyPayment: 0,
      netAmountFinanced: 0,
      ppsrFee: 0,
      product: 'Consumer Loan Access',
      totalAmountFinanced: 0,
      weeklyPayment: 0,
      financeHeading: '',
      cmpFrom: 0,
      cmpTo: 0,
      creditScore: 0, 
      isTpr: null
    },
    tpr: {
      leadId: '',
      pegaCase: '',
      guestRateIdentifier: '', 
      validityDate: '',
      detailIdentifier: '', 
      tprEnabled: true, 
      isTprDown: '', 
      hasLoadedWithTpr: false
    },
    customer: {
      orderType: 1,
      companyName: '',
      abn: '',

      title: '',
      firstName: '',
      preferredName: '',
      lastName: '',
      dob: '',
      preferredDealership: '',
      phone: '',
      email: '',

      address: '',
      unitNo: '',
      streetNo: '',
      streetName: '',
      streetType: '',
      streetAddress: '',
      suburb: '',
      state: '',
      postcode: '',

      sa1Code: '',
      subPremise: '',
      
      privacyAccepted: false,
      financeLeadPostcode: '',
      financeLeadPrivacyAccepted: false,

      privacyAcceptedFallback: false,
      businessUse: false,

      privacyAcceptedTpr: false,
      livingStatus: '', // Own = 1, Purchasing = 2, Renting = 3, Boarding = 4, LivingWithParents = 5
      licenseOrigin: '',
      licenseNumber: '',
      licenseState: '',
      otherProperty: '',
      recaptcha: '', 
      additionalComment: '',

      hasSubmittedLead: false

      // driverLicenseCountry: '',
      // driverLicenseState: ''
    },
    financeApplication: {
      preCheckout: false,
      preCheckoutAttempted: false,
      preCheckoutCompleted: false,
      preCheckoutReturn: 'purchaseOptions',
      viewVersion: null,
      doLater: false,
      loadedFromLink: false,
      finishedLoadingFromLink: false,
      submitted: false,
      submissionError: false,
      privacyAccepted: false,
      receiptNumber: 0,
      dobday: '',
      dobmonth: '',
      dobyear: '',
      OrderFinance: { // actual order object
        OrderID: null,
        Order: null,
        OrderGuid: '',
        OrderHash: '',
        LoanType: 1,
        Applicant: {
          Title: '',
          FirstName: '',
          MiddleName: '',
          LastName: '',
          Telephone: '',
          AlternateTelephone: '',
          Email: '',
          DateOfBirth: '', // 2000-01-01
          CorrespondenceMethod: 1, // Electronic = 1, Paper = 2
          MailingAddress: {
            UnitNumber: '',
            StreetNumber: '',
            StreetName: '',
            StreetType: '',
            Address: '',
            Suburb: '',
            Postcode: '',
            State: ''
          },
          NumberOfDependents: 0,
          DriverLicenseNumber: '',
          DriverLicenseState: '',
          DriverLicenseExpiry: ''
        },
        ResidenceOwnershipInfo: {
          OwnershipStatus: '', // Own = 1, Purchasing = 2, Renting = 3, Boarding = 4, LivingWithParents = 5
          MortgageProvider: '',
          MonthlyMortgagePayment: 0,
          HomeValue: 0,
          BalanceOwingOnHomeMortgage: 0,
          LandlordName: '',
          MonthlyRent: 0
        },
        ResidenceInfo: [],
        EmploymentDetailInfo: [],
        OtherIncomes: [],
        Expenses: {
          Groceries: 0,
          Household: 0,
          Utilities: 0,
          VehiclesAndTransport: 0,
          Insurance: 0,
          EducationAndChildcare: 0,
          LeisureAndEntertainment: 0,
          Other: 0
        },
        Debts: [],
        Assets: [],
        Liabilities: [],
        FinanceStatus: 1,
        DealerFinanceStatus: 1,
        ForseeableChanges: null,
        ChangeReductionIncome: false,
        ChangeIncreaseInExpenses: false,
        ChangeOther: false,
        ChangeAdditionalInformation: '',
        MeetsRequirements: null,
        TermsAccepted: false
      }
    },
    tradeIn: {
      hasTradeIn: false,
      vehicleModel: '',
      year: '',
      km: '',
      comment: ''
    },
    tradeValuationApp: {
      tradeValuationEnabled: false,
      tradeValuationSubmitted: false,
      tradeValuationEnquiryId: null,
      apiAttempts: 0, 
      step: 1,
      minYear: ((new Date()).getFullYear() - 10),
      // api: 'https://stagingvaluation.toyotainventory.com.au/api',
      // auth: 'https://stagingvaluation.toyotainventory.com.au/oAuth/token',
      // bearerToken: '',
      car: {
        vin: '',
        year: '',
        make: '',
        model: '',
        makeCode: '',
        modelCode: '',
        modelYears: [],
        variants: {},
        variant: '',
        modelName: '',
        image: '',
        nvic: '',
        kilometres: '',
        valuationData: null, 
        formData: {
          carImage:"",
          carMake:"",
          carModel:"",
          carName:"",
          carNvic:"",
          carVIN:"",
          carVariant:"",
          carYear:"",
          inputSearch:"",
          inputValue:"",
          makeCode:"",
          mileage:"",
          modelCode:""
        }, 
        optionData: {}
      }
    },
    promoCode: {
      Applied: false,
      PromoTypeName: '',
      Code: '',
      Email: '',
      Description: '',
      Disc: '', 
      DiscSymbol: '',
      NeedsAuth: false,
      PromoType: 0,
      PromoValue: 0,
      SysDesc: '',
      Type: '',
      Valid: false,
      ValueDesc: '', 
    },
    openModal: {
			id: '', 
			title: '',
			message: '', 
      video: false,
			buttons: []
    }, 
    disclaimers: [], 
    dataLayer: {}
  },
  mutations: {
    resetOpenModal(state){
			state.openModal.id = '';
			state.openModal.title = '';
			state.openModal.message = '';
			state.openModal.video = false;
			Vue.set(state.openModal, 'buttons', []);
		},
		setOpenModal(state, payload){
			state.openModal.id = payload.id;
			state.openModal.title = payload.title ? payload.title : '';
			state.openModal.message = payload.message ? payload.message : '';
			state.openModal.video = !!payload.video;
			Vue.set(state.openModal, 'buttons', payload.buttons ? [...payload.buttons] : []);
		},
    setError(state, payload){
			if (state.openModal.id !== 'ErrorModal'){
				state.openModal = {...state.openModal, id: 'ErrorModal', ...payload};
			}
    },
    setFsl(state, payload){
      state.fsl = payload;
    },
    setIsFslEnabled(state, payload: boolean){
      state.app.isFslEnabled = payload;
    },
    setValueAdd(state, payload){
      state.valueAdd.hasRetrievedData = true;
      state.valueAdd.data = payload;
    },
    setValueAddAnalytics(state, payload){
      state.valueAdd.hasSentAnalytics = payload;
    },
    setPromoCode(state, payload){
      state.promoCode = {...state.promoCode, ...payload};
    },
    setFinance(state, payload){
      state.finance = {...state.finance, ...payload};
    },
    setFinanceApplication(state, payload){
      state.finance = {...state.financeApplication, ...payload};
    },
    setVehicle(state, payload){
      state.vehicle = {...state.vehicle, ...payload};
    },
    setApp(state, payload){
      state.app = {...state.app, ...payload};
    },
    setAgent (state, payload) {
      state.agent = {...state.agent, ...payload};
    },
    setDealer (state, payload) {
      state.dealer = {...state.dealer, ...payload};
    },
    setCustomer(state, payload){
			state.customer = {...state.customer, ...payload};
    },
		addDisclaimer(state, payload){
      (state.disclaimers as any) = [...state.disclaimers, payload];
		},
		setDisclaimers(state, payload){
			Vue.set(state, 'disclaimers', [...payload]);
    },
    setDataLayer(state, payload){ 
      state.dataLayer = {...state.dataLayer, ...payload};
    },
    // setOrderNumber(state, payload){
		// 	state.app.orderNumber = payload;
		// },
		setTpr(state, payload){
			state.tpr = {...state.tpr, ...payload};
		},
		setTradeIn(state, payload){
			state.tradeIn = {...state.tradeIn, ...payload};
		},
		setTradeApp(state, payload){
			state.tradeValuationApp = {...state.tradeValuationApp, ...payload};
		},
  },
  actions: {
    setLoading({ commit }, payload){
      if (payload){
        commit('setOpenModal', {id:'LoadingModal'});
      }
      else {
        commit('resetOpenModal');
      }
    }, 
    removeDisclaimer({ commit, state }, payload){
      const disclaimers = state.disclaimers.filter(d => d['type'] != payload);
      commit('setDisclaimers', disclaimers);
		},
    addDisclaimer({ commit }, payload){
      commit('addDisclaimer', payload);
    }
  }, 
  getters: {
    flow: (state, getters) => {
      if (state.app.paymentMethod == 'cash'){
        return 'cash';
      }
      else if (state.app.paymentMethod == 'fsl'){
        return 'fsl';
      }
      else if (state.customer.orderType == 2 && state.finance.internalFinanceType == 'Campaign' && getters.isBusinessFinanceAvailable){
        return 'business-campaign';
      }
      else if (state.customer.orderType == 2){
        return 'business';
      }
      else if(state.finance.internalFinanceType == 'Campaign'){
        return 'campaign'
      }
      else if(state.finance.internalFinanceType == 'Indicative' && state.tpr.tprEnabled){
        if (state.finance.isTpr){
          return 'adjust-tpr';
        }
        else if(!state.tpr.isTprDown){
          return 'get-tpr'
        }
        else {
          return 'indicative';
        }
      }
      else if(state.finance.internalFinanceType == 'Indicative'){
        return 'indicative';
      }
    },
    captchaSiteKey(): string {
			return ((window as any).AppMode === 'app' ||  (window as any).AppMode === 'stagingapp') ? '6LfjD8EUAAAAAHWKFtKD1Yxx7HZWzOafhrUjBSbR' : '6LfN8bEUAAAAAHycQXBxTvtI-n86hl6ENhyVXqke';
		},
    isBusinessFinanceAvailable: state => {
      return !!state.vehicle.availableFinanceSetting.filter(o => (o as any).TypeOfFinance == 2).length;
    },
    isPersonalFinanceAvailable: state => {
      return !!state.vehicle.availableFinanceSetting.filter(o => (o as any).TypeOfFinance == 1).length;
    },
    amountChargedToday: state => {
      return state.app.paymentMethod === 'finance' ? state.app.financePayToday : state.app.cashDeposit;
    },
    vehicleFinalDeposit: state => {
      return state.app.paymentMethod === 'cash' ? state.app.cashDeposit : state.app.financePayToday;      
    },
    vehicleFinalPrice: state => {
      if (state.promoCode.Applied && state.promoCode.PromoType == 1){
        let discount: number = parseFloat(state.promoCode.PromoValue as any) || 0;
        if (isNaN(discount)) discount = 0;
        return state.vehicle.price - discount;
      }
      else {
        return state.vehicle.price;
      }
    }, 
    showPriceDisclaimer: state => {
			return state.vehicle.type == "New Virtual" && !state.vehicle.vin && !state.vehicle.batchNumber && !state.app.disableDisclaimer;
		}
  }
})
