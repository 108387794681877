import Moment from 'moment';

const Helpers = {
	livingStatus: [
		{value: "2", label: 'Own Home (Mortgage)'},
		{value: "1", label: 'Own Home (No Mortgage)'},
		{value: "3", label: 'Renting'},
		{value: "4", label: 'Boarding'},
		{value: "5", label: 'Living With Parents'}
	],
	getFinanceHeadingListItems(financeHeading: string): Array<string> {
		financeHeading = financeHeading.replace(/\./g, ".\n");
		financeHeading = financeHeading.replace(/ and a/g, ".\n");
		const financeHeadingArr = financeHeading.split(/\n/g);
		const items = [];
		for (let i = 0; i != financeHeadingArr.length; ++i){
			let newTxt = financeHeadingArr[i].trim();
			newTxt = newTxt.charAt(0).toUpperCase() + newTxt.slice(1);
			if (newTxt.length){
				items.push(newTxt);
			}
		}
		return items;
	},
	getEnquirySources(): any {
		return {
			'Normal': 1,
			'KIOSK_SHOWROOM': 2,
			'KIOSK_SERVICE': 3,
			'QR_SHOWROOM': 4,
			'QR_SERVICE': 5,
			'SDL_QR': 6
		};
	},

	getLongestCommonStartingSubstring(arr1: Array<any>): string {
		const arr= arr1.concat().sort();
		const a1= arr[0];
		const a2= arr[arr.length-1];
		const L= a1.length;
		let i= 0;
		while(i< L && a1.charAt(i)=== a2.charAt(i)) i++;
		return a1.substring(0, i);
	},
	formattedDateMinusOneDay(d: any): string {
		const m = Moment(d).subtract(1, 'days');
		return m.format('Do MMMM YYYY');
	},
	formattedDate(d: any): string {
		const m = Moment(d)
		return m.format('Do MMMM YYYY');
	},
	shortFormattedDate(d: any): string {
		const m = Moment(d)
		return m.format('D MMM YYYY');
	},
	extractDisclaimerKeys(disclaimer: string): Array<any>{
		const keys = [];
		const arr = disclaimer.split('[');
		for (let i = 0; i != arr.length; ++i){
			if (arr[i].length){
				const arr2 = arr[i].split(']');
				if (arr2.length){
					keys.push('['+arr2[0]+']');
				}
			}
		}
		return keys;
	},
	getCookie(name: string): string {  
		const match = document.cookie.match(new RegExp(name + '=([^;]+)'));
		if (match) return match[1];
		return '';
	},
	getNumberFromString(s: string): number {
		return parseInt(s.replace(/[^\d]/g, ''));
	}, 
	getFormattedPrice(p: number): string {
		const whole = Math.floor(p);
		const remainder = p - whole;
		const formatter = new Intl.NumberFormat('en-AU', {
			style: 'currency',
			currency: 'AUD',
			minimumFractionDigits: remainder ? 2 : 0
		});
		return formatter.format(p);
	}, 
	getColour(code: string): Array<any> {
		const colourMap: any = {
			'040': '#fff',
			'219': '#000',
			'218': '#000',
			'LB20': '#000',
			'3U5': 'red',
			'EA20': '#000'
		};
		return code ? colourMap[code] || '' : '';
	}, 
	clog(v: any): void {
		if (-1 != location.hostname.indexOf('localhost') || -1 != location.hostname.indexOf('test.dealer')){
			console.log(v);
		}
	}
}
export default Helpers;
