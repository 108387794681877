






































// v-textarea just a copy of v-text-field
import Vue from 'vue';
import Validation from '../../mixins/validation';
export default Vue.extend({
	name: 'FieldText', 
	props: {
		rules: Array, 
		validateNow: Boolean, 
		disabled: Boolean, 
		disableAutofill: Boolean, 
		name: String, 
		label: String, 
		value: String, 
		hint: String, 
		type: String, 
		errorMessages: String, 
		autocomplete: String
	},
	data(): any {
		return {
			field: {
				value: null, 
				isValid: false, 
				hasBlurred: false, 
				hasValidated: false
			}
		};
	}, 
	computed: {
		errorMessage(): string {
			if (this.errorMessages){
				return this.errorMessages;
			}
			return this.field.value && !this.field.isValid && this.hint ? this.hint : ''
		}
	},
	methods: {
		handleFieldBlur(): void {
			this.field.hasBlurred = true;
			Validation.setFieldValid(this.field, this.rules);
			this.handleFieldInput();
			this.$emit('blur', this.field.isValid);
		},
		handleFieldFocus(): void {
			this.$emit('focus');
		}, 
		handleFieldInput(): void {
			Validation.setFieldValid(this.field, this.rules);
			if (this.field.isValid){
				this.field.hasValidated = true;
			}
			this.$emit('input', this.field.isValid ? this.field.value : '');
		}
	}, 
	mounted(){
		if (this.value){
			this.field.value = this.value;
			this.field.isValid = true;
			this.field.hasValidated = true;
		}
	}
});
