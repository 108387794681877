









































































  import Vue from 'vue';
  import router from './router';
  import { mapState } from 'vuex';
  import Moment from 'moment';
  import Helpers from './mixins/helpers';
  import Api from './mixins/api';
  import axios from 'axios';
  import VueScrollTo from 'vue-scrollto';
  import disclaimerData from '@/content/disclaimers.json';
  import LoadingModal from '@/components/dumb/LoadingModal.vue';
  import ErrorModal from '@/components/dumb/ErrorModal.vue';
  import PrivacyModal from '@/components/dumb/PrivacyModal.vue';
  import TermsModal from '@/components/dumb/TermsModal.vue';
  import AcknowledgementModal from '@/components/dumb/AcknowledgementModal.vue';
  import LoginModal from '@/components/LoginModal.vue';
  import InfoModal from '@/components/dumb/InfoModal.vue';
  import AlertBanner from '@/components/dumb/AlertBanner.vue';

	Vue.use(VueScrollTo);

  export default Vue.extend({
    name: 'App',
    components: {
      LoadingModal, 
      ErrorModal, 
      PrivacyModal, 
      TermsModal, 
      AcknowledgementModal, 
      LoginModal, 
      InfoModal, 
      AlertBanner
    },
    data(){
      return {
        logoutTimeRemaining: 0,
        logoutInterval: 0,
        isDealerReady: false, 
        isVehicleReady: false,
        isFinanceReady: false,  
        imageSuffixes: ['003', '012', '015', '022', '033']
      };
    }, 
    computed: {
      ...mapState({
        app(): any { return this.$store.state.app },
        dealer(): any { return this.$store.state.dealer },
        vehicle(): any { return this.$store.state.vehicle },
        customer(): any { return this.$store.state.customer },
        finance(): any { return this.$store.state.finance },
        tpr(): any { return this.$store.state.tpr }, 
        promoCode(): any { return this.$store.state.promoCode },
        disclaimers(): any { return this.$store.state.disclaimers },
        openModal(): any { return this.$store.state.openModal }
      }),
      isLoading(): boolean {
        return this.app.isLoading;
      },
      isReady(): boolean {
        return (this.isDealerReady && this.isVehicleReady && this.isFinanceReady) || 'RedirectPage' == this.$route.name;
      }, 
      flow(): string {
        return this.$store.getters.flow;
      }, 
      alertMessage(): string {
        return  ``;// `Due to the unprecedented global demand, we are currently experiencing extended wait times which vary across our model range. This may result in a different specification and/or different price point at <strong>time of delivery</strong>. More information is available <a href="https://www.toyota.com.au/support/delivery-wait-times-availability" target="_blank">here</a>. Please contact us for the latest update. `; //this.vehicle.model.toUpperCase().includes('RAV4') ?
      }
    },
    watch: {
      'isReady'(v): void {
        this.$store.dispatch('setLoading', !v);
      }, 
      'app.session'(v): void {
        v ? this.handleLogin() : this.handleLogout();
      }, 
      'app.step'(v): void {
        if (this.app.step > 1){
          this.refreshSession();
        }
      }, 
      $route(to, from){
        if (to.name != 'SummaryPage'){
          this.refreshSession();
        }
        else {
          if (this.app.myToyotaId){
            this.$store.commit('setApp', {hasCompletedWithMyToyotaId: true});
            this.handleLogout();
          }
        }
      }
    },
    methods: {
      showDisclaimer(d: any): boolean {
        if (d.for == "valueAdd"){
          return this.app.showValueAdd;
        }
        else if (d.type == 'finance' && this.$route.name == 'SummaryPage'){
          return this.flow == 'adjust' || this.flow == 'get-tpr' || this.flow == 'adjust-tpr' || this.flow == 'campaign' || this.flow == 'business-campaign';
        }  
        else if (d.type == 'finance'){
          return this.flow != 'get-tpr' && this.flow != 'business' && this.flow != 'cash';
        } 
        else if (this.$route.name !== 'SummaryPage' && (d.type === 'myToyota')){
          return false;
        } 
        else if (d.type === 'financedecoration' && this.app.step !== 1 && this.$route.name !== 'SummaryPage'){
          return this.flow === 'get-tpr'  || this.flow === 'indicative';
        } 
        else if (d.type === 'financedecoration'){
          return this.flow != 'get-tpr' && this.flow != 'business' && this.flow != 'cash' && this.flow != 'campaign' && this.flow != 'business-campaign'  &&  this.flow !== 'adjust-tpr';
        }
        return true;
      },
      refreshSession(): void {
        if (!this.app.session) return;
        Helpers.clog('resetting session / timer');
        const url = Api.returnRefreshSessionUrl();
        const headers = {
          headers: {
            'my_token': this.app.session
          }
        };
        Helpers.clog(url);
        Helpers.clog('header session: ' + this.app.session);

        // (window as any).gigya.accounts.getJWT({callback:(r: any) =>{
        //   console.log('jwt pre refresh');
        //   console.log(r);
        // }});
        // (window as any).gigya.accounts.getAccountInfo({callback:(r: any)=>{
        //   console.log('account info pre refresh');
        //   console.log(r);
        // }});

        axios.get(url, headers).then((response: any) => {
          Helpers.clog(response);
          if (response.data.statusCode == 200){
            const mins = 10;
            let dateexp = new Date();
            dateexp = new Date(dateexp.getTime() + mins*60000);
            const expires = "expires="+ dateexp.toString();
            const cookieData = ";" + expires + ';path=/';
            document.cookie = response.data.sessionInfo.cookieName + '=' + response.data.sessionInfo.cookieValue + cookieData;
            Helpers.clog(`setting cookie ${response.data.sessionInfo.cookieName} with value: ${response.data.sessionInfo.cookieValue}${cookieData}`);
            Helpers.clog('Gigya refreshUI');
            (window as any).gigya.socialize.refreshUI({callback: this.refreshSessionCallback});
          }
          else {
            console.log('Error refreshing session');
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      refreshSessionCallback(response: any): void {
        Helpers.clog(response);
        if (response.errorCode == 0){             
          Helpers.clog('Refreshed session');
          this.resetTimer();
          (window as any).gigya.accounts.getJWT({callback:(response: any) => {
            console.log('jwt post refresh');
            console.log(response);
            if (response.id_token){
              Helpers.clog('setting session to id_token');
              Helpers.clog(response.id_token);
              this.$store.commit('setApp', {session: response.id_token});
              (window as any).gigya.accounts.getAccountInfo({callback:(r: any)=>{
                console.log('account info post refresh');
                console.log(r);
              }});
            } 
            else {
              console.log('error. no id token.');
              this.$store.commit('setApp', {session: ''});
            }
          }});
        }
        else {
          Helpers.clog('Error :' + response.errorMessage);
        }
      },
      handleLogin(): void {
        this.resetTimer();
      },
      resetTimer(): void {
        if (this.logoutInterval) clearInterval(this.logoutInterval);
        this.logoutTimeRemaining = this.app.sessionTimeout;
        this.logoutInterval = setInterval(() => {
          this.logoutTimeRemaining = this.logoutTimeRemaining - 1;
          if (this.logoutTimeRemaining == 120){
            this.$store.commit('setOpenModal', {
              id: 'InfoModal',
              title: 'myToyota',
              message: `Your session will expire soon. Would you like to continue?`,
              buttons: [
              //   {
              //   text: 'Logout',
              //   type: 'text', 
              //   callback: () => {
              //     this.$store.commit('setApp', {session:''});
              //     this.$store.commit('resetOpenModal');
              //   }
              // }, 
              {
                text: 'Continue',
                callback: () => {
                  this.refreshSession();
                  this.$store.commit('resetOpenModal');
                }
              }]
            });
          }
          else if(this.logoutTimeRemaining == 0)
          {
            clearInterval(this.logoutInterval);
            this.$store.commit('setApp', {session:''});
          }
        }, 1000);
      },
      handleLogout(): void {
        if (this.logoutInterval) clearInterval(this.logoutInterval);
        if (this.openModal.id == 'InfoModal') this.$store.commit('resetOpenModal');
        (window as any).gigya.accounts.logout({
          callback: this.logoutCallback
        });
      },
      logoutCallback(): void {
        Helpers.clog('setting myToyotaId to empty string');
        this.$store.commit('setApp', {myToyotaId:''});
      },
      setEnquirySourceCookie(source: string): void {
        document.cookie = `ipadutm=${(source)};expires=Thu, 01 Jan 2070 00:00:00 GMT`;
      },
      getEnquirySources(source: string): void {
        const url = Api.returnEnquirySourceUrl((window as any).StoreWebsiteInfo.DealerID);
        Helpers.clog(url);
        axios.get(url).then((response) => {
          Helpers.clog(response);
          if (response.data && response.data.length){
            const s = response.data.filter((o: any) => o.Source == source);
            if (s.length){
              this.$store.commit('setApp', {
                enquirySource: {
                  id: s[0].UtmID, 
                  suburb: s[0].Suburb, 
                  value: source
                }
              });
              this.setEnquirySourceCookie(source);
            }
            else {
              Helpers.clog('no match found for UTM');
            }
          }
        }).catch((response) => {
            console.log('Error receiving UTMs');
            console.log(response);
        })
      },
      checkGuid(): void {
        const url = Api.returnGuidCheckUrl(this.tpr.guestRateIdentifier);
        axios.get(url).then((response: any) => {
          Helpers.clog(response);
          const rExpiry = new Date(response);
          const gExpiry = new Date(this.tpr.validityDate);
          const rex = {
              day: rExpiry.getDate(),
              month: rExpiry.getMonth(),
              year: rExpiry.getFullYear()
          };
          const gex = {
              day: gExpiry.getDate(),
              month: gExpiry.getMonth(),
              year: gExpiry.getFullYear()
          };
          const isValid = (rex.year <= gex.year) && (rex.month <= gex.month) && (rex.day <= gex.day);
          if (!isValid){
            Helpers.clog('deleting cookies (chrome sucks on localhost)');
            document.cookie = 'tprguid=;expires=' + (new Date(0).toUTCString()) + ';';
            document.cookie = 'tprcaseid=;expires=' + (new Date(0).toUTCString()) + ';';
            // window.location.reload(); // chrome bug infinite reload.
            this.$store.commit('setTpr', {
              pegaCase: '',
              guestRateIdentifier: '', 
              validityDate: '',
              detailIdentifier: ''
            });
          }
          this.isFinanceReady = true;
        }).catch((response) => {
            Helpers.clog(response);
            this.isFinanceReady = true;
        });
      },
      getFinance(vehicleData: any): void {
        const guid = this.customer.orderType == 1 && this.tpr.tprEnabled ? this.tpr.guestRateIdentifier : '';
        const sliders = Helpers.getCookie("financeoptions");
        const financeOptions = sliders ? sliders.split("_") : [
          vehicleData.initialFinanceSetting.DepositRequired || this.app.financeDeposit, vehicleData.initialFinanceSetting.TermInYears, vehicleData.initialFinanceSetting.KmsPerYear
        ];
        const termsInYears = parseInt(this.$route.query.t || financeOptions[1]);
        const kms = parseInt(this.$route.query.k || financeOptions[2]);
        let financeDeposit = parseInt(this.$route.query.f || financeOptions[0]);
        if (!isNaN(financeDeposit)){
          financeDeposit = Math.min(financeDeposit, vehicleData.maximumDeposit);
          financeDeposit = Math.max(financeDeposit, this.dealer.depositMinimum);
        }
        financeDeposit = !isNaN(financeDeposit) ? financeDeposit : 100;
        this.$store.commit('setApp', {
          loanTerm: !isNaN(termsInYears) ? termsInYears : 0,
          kmsPerYear: !isNaN(kms) ? kms : 0, 
          financeDeposit: financeDeposit
        });

        Api.getUpdatedfinance(this, (financeData: any) => {
          if (financeData.rateExpiry){
            this.$store.commit('setTpr', {
              validityDate: financeData.rateExpiry
            });
          }
          const isBadDate = -1 != this.finance.rateExpiry.indexOf("0001-");
          const isIndicative = financeData.internalFinanceType === 'Indicative';

          this.$store.commit('setApp', {
            financeDeposit: financeData.financeDeposit
          });

          if (this.tpr.guestRateIdentifier && isIndicative && !this.finance.isTpr && isBadDate){
            this.checkGuid();
          }
          else {
            this.isFinanceReady = true;
          }
        }, () => {
          this.isFinanceReady = true;
        });
      },
      getVehicleImages(): void {
        const url = Api.getVehicleImagesURL(this.vehicle.materialCode, this.vehicle.produced);
        if (!url){
          this.$store.commit('setError', {
            message: `Sorry, your vehicle could not be loaded.`
          });
          this.isVehicleReady = true;
          return;
        }
        Helpers.clog(url);
        axios.get(url).then((response) => {
          Helpers.clog(response);
          if (response.statusText == "OK"){
            const images360: Array<any> = response.data.Path_360;
            const imagesHero: any = {};
            let img = '';
            for (let i = 0; i != response.data.Path_Hero.length; ++i){
              const s = response.data.Path_Hero[i].Sizes[response.data.Path_Hero[i].Sizes.length-2];
              const s2 = response.data.Path_Hero[i].Sizes[response.data.Path_Hero[i].Sizes.length-1]
              const imageUrl = `${response.data.Path_Hero[i].Path}/jpg/lo/${s}/${response.data.Path_Hero[i].Prefix}.jpg`;
              switch (response.data.Path_Hero[i].heroDesc){
                case "SPR": 
                  imagesHero.side = imageUrl;
                  img = imageUrl.replace(/jpg/g, 'png').replace(s, s2);
                  break;
                case "FL34":
                  imagesHero.left = imageUrl;
                  break;
                case "RR34":
                  imagesHero.back = imageUrl;
                  break;
                case "FR34":
                  imagesHero.right = imageUrl;
                  break;
              }
            }
            const vehicleData: any = {
              image: img,
              images360: images360,
              imagesHero: imagesHero
            }
            this.$store.commit('setVehicle', vehicleData);
          }
          else {
            this.$store.commit('setError', {
              message: `Sorry, your vehicle could not be loaded.`
            });
          }
          this.isVehicleReady = true;
        }).catch((response) => {
            Helpers.clog(response)
            this.$store.commit('setError', {
              message: `Sorry, your vehicle could not be loaded.`
            });
            this.isVehicleReady = true;
        });
      },
      getVehicle(): void {
        const materialCode = this.$route.query.m ? this.$route.query.m.toString() : '';
        const vehicleSpecificationID = this.$route.query.v ? this.$route.query.v.toString() : '';
        const batchNumber = this.$route.query.b ? this.$route.query.b.toString() : '';
        // const queryDealerID = (window as any).StoreWebsiteInfo.StockDealerID === 0 ? (window as any).StoreWebsiteInfo.DealerID : (window as any).StoreWebsiteInfo.StockDealerID;
        const url = Api.getVehicleURL(materialCode, vehicleSpecificationID, batchNumber, this.dealer.queryDealerID);
        Helpers.clog('getVehicle');
        if (url){
          Helpers.clog(url);
          axios.get(url).then((response) => {
            Helpers.clog(url);
            Helpers.clog(response);
            // make sure price is not silly
            if (parseFloat(response.data.Price) < 10000){
              this.$store.commit('setError', {
                message: `Sorry, your vehicle could not be loaded.`
              });
              this.isVehicleReady = true;
            } 
            else {
              const isFsl: boolean = response.data.FslAvailable;
              this.$store.commit('setIsFslEnabled', isFsl);

              const availableFinanceTypes = ['finance'];
              if (response.data.FslAvailable){
                availableFinanceTypes.push('fsl');
              }
              const preferredFinanceType = Helpers.getCookie('selectedFinanceType') || '';
              const defaultFinanceType = 'finance';
              const initialFinanceType = preferredFinanceType && availableFinanceTypes.includes(preferredFinanceType) ? preferredFinanceType : defaultFinanceType;
              const selected = initialFinanceType || 'finance';
              this.$store.commit('setApp', {
                paymentMethod: selected
              });

              // make nice car name
              const modelRegExp = new RegExp(response.data.Model, 'i');
              const transmissionRegExp = new RegExp(response.data.TransmissionType, 'i');
              const strippedFullDescription = response.data.FullDescription.replace(modelRegExp, '').replace(transmissionRegExp, '').trim();

              let specialExtraInfo = response.data.SpecialItem ? response.data.SpecialItem.SpecialExtraInfo : '';
              if (response.data.SpecialItem && !specialExtraInfo && response.data.SpecialItem.SpecialExtra && response.data.SpecialItem.SpecialExtra.length){
                  specialExtraInfo = response.data.SpecialItem.SpecialExtra[0];
              }
              // make vehicle data object
              const vehicleData: any = {
                vehicleType: response.data.VehicleType,
                materialCode: materialCode,
                vehicleSpecificationID: parseFloat(vehicleSpecificationID) || (parseFloat(response.data.VehicleSpecificationID) || 0),
                variantCode: response.data.VariantCode,
                batchNumber: batchNumber,
                vin: response.data.Vin || '',
                type: batchNumber ? 'In Stock' : 'New Virtual',
                model: response.data.Model,
                grade: response.data.Grade,
                gradeLevelDescription: response.data.GradeLevelDescription,
                price: response.data.Price,
                transmission: response.data.TransmissionType,
                fuel: response.data.Fuel,
                bodyColour: response.data.PaintName,
                interiorColour: response.data.TrimName,
                inclOption: response.data.FactoryOptionDescription,
                canBeFinanced: response.data.CanBeFinanced,
                financeFailureReason: response.data.FinanceFailureReason,
                termOffset: response.data.TermOffset,
                fullDescription: response.data.FullDescription,
                strippedFullDescription: strippedFullDescription,
                gfv: response.data.GFV,
                hasNationalSpecial: response.data.HasNationalSpecial,

                specialItem: response.data.SpecialItem, 
                specialExtraInfo: specialExtraInfo,
                specialUniqueName: response.data.HasNationalSpecial && response.data.SpecialItem ? response.data.SpecialItem.SpecialUniqueName : '',

                initialFinanceSetting: response.data.InitialFinanceSetting,
                availableFinanceSetting: response.data.AvailableFinanceSetting,
                maximumDeposit: response.data.MaximumDeposit,
                paintName: response.data.PaintName,
                trimName: response.data.TrimName,
                produced: response.data.Produced ? response.data.Produced : '', 
                backLink: response.data.BackLink, 
								backLinkListing: response.data.BackLinkListing
              }
              if (response.data.SpecialItem){
                const driveawayDisclaimerArray = response.data.SpecialItem.Disclaimers;
                if (driveawayDisclaimerArray.length > 0){
                  vehicleData.disclaimer = driveawayDisclaimerArray[0].Value;
                  vehicleData.disclaimerKey = driveawayDisclaimerArray[0].Key;
                  Helpers.clog('Removing default standardDriveaway disclaimer and adding driveaway (specialItem)');
                  this.$store.dispatch('removeDisclaimer', "standardDriveaway");
                  this.$store.commit('addDisclaimer', {
                      type: "driveaway", 
                      for: 'vehicle',
                      key: driveawayDisclaimerArray[0].Key, 
                      text: driveawayDisclaimerArray[0].Value
                  });
                }
                if (response.data.SpecialItem.Disclaimers.length > 1){
                  const extraVehicleDisclaimersArray = response.data.SpecialItem.Disclaimers.filter((v: any) => v['Type'] !== 'Driveaway')
                  if (extraVehicleDisclaimersArray.length > 0){
                    for (const i in extraVehicleDisclaimersArray) {
                      this.$store.commit('addDisclaimer', {
                        type: driveawayDisclaimerArray[0].Type, 
                        for: 'vehicle',
                        key: driveawayDisclaimerArray[0].Key, 
                        text: driveawayDisclaimerArray[0].Value
                      });
                    }
                  }
                }
              } 
              else {
                const standardDriveaway = disclaimerData.disclaimers.filter(d => d.type == "standardDriveaway");
                if (standardDriveaway.length){
                  vehicleData.disclaimer = standardDriveaway[0].text;
                  vehicleData.disclaimerKey = standardDriveaway[0].key;
                  Helpers.clog('Removing default standardDriveaway disclaimer and adding standardDriveaway');
                  this.$store.dispatch('removeDisclaimer', "standardDriveaway");
                  this.$store.commit('addDisclaimer', {
                    type: "standardDriveaway", 
                    for: 'vehicle',
                    key: standardDriveaway[0].key, 
                    text: standardDriveaway[0].text
                  });
                }
              }
              this.$store.commit('setVehicle', vehicleData);

              // if (!vehicleData.initialFinanceSetting.TprEnabled){
              //   this.$store.commit('setTpr', {
              //     tprEnabled: false
              //   });
              // }
              if (vehicleData.canBeFinanced && parseFloat(vehicleData.gfv) > 1){
                this.$store.commit('setApp', {
                  isFinanceAvailable: true
                });
                this.getFinance(vehicleData);
              }
              else {
                this.$store.commit('setApp', {
                  isFinanceAvailable: false, 
                  paymentMethod: 'cash'
                });
                this.isFinanceReady = true;
              }
              this.$store.commit('setApp', {
                changeCarUrl: vehicleData.backLink
              });
              if (!this.app.referrer){
                this.$store.commit('setApp', { referrer: response.data.BackLinkListing });
              }
              // // get an NVI link to return them to if they change car
              const changeCarUrl = Api.getChangeCarURL(this.dealer.dealerID, vehicleData.variantCode, vehicleData.vehicleSpecificationID);
              Helpers.clog(changeCarUrl);
              axios.get(changeCarUrl).then((response) => {
                Helpers.clog(response);
                if (response.data.DealerExists && response.data.VehicleExists){
                  const i = response.data.Url.indexOf("/", 9);
                  const url = response.data.Url.substring(i);
                  this.$store.commit('setApp', {
                    changeCarUrl: url
                  });
                }
              }).catch((error) => {
                Helpers.clog(error)
              });
              this.getVehicleImages();
            }
          })
          .catch((response) => {
            Helpers.clog(response)
            this.$store.commit('setError', {
              message: `Sorry, your vehicle could not be loaded.`
            });
            this.isVehicleReady = true;
          })
        // making api url fails for some reason
        } 
        else {
          this.$store.commit('setError', {
            message: `Sorry, your vehicle could not be loaded.`
          })
        }
      },
      getUserInfo(): any {
        const key = this.$route.query.d || this.$route.query.di;
        const guest = this.tpr.guestRateIdentifier;
        const auth = this.$route.query.m;
        if (key && auth && guest){
          const url = Api.getUserInfoUrl(guest, auth, key);
          axios.get(url).then((response) => {
            Helpers.clog(url);
            Helpers.clog(response);
            this.$store.commit('setCustomer', Api.getUserInfoResponse(response.data));
          })
          .catch((response) => {
              Helpers.clog(response);
          })
        }    
      }, 
      getDealer(): any {
        const dealer = (window as any).StoreWebsiteInfo;
        const queryDealerID = dealer.StockDealerID === 0 ? dealer.DealerID : dealer.StockDealerID;
        const storeUrl = 'https://' + (window as any).StoreWebsiteInfo.StoreUrl + '.dealer.toyota.com.au';

        this.$store.commit('setDealer', {
          dealerName: dealer.DealerName,
          locationName: dealer.LocationName,
          dealerID: dealer.DealerID,
          stockDealerID: dealer.StockDealerID,
          queryDealerID,
          nviUrl: storeUrl,
          depositMinimum: dealer.DepositAmount || 100,
          depositAmount: dealer.DepositAmount || 100
        });
        this.$store.commit('setApp', {
          financePayToday: dealer.DepositAmount || 100, 
          cashDeposit: dealer.DepositAmount || 100, 
          cashDepositBackup: dealer.DepositAmount || 100, 
        });
        this.getAgent();
        this.getDealerInfo();
        // this.commitVehiclesAvailable(dealer.DealerID);
      },
      getDealerInfo(): void {
        const url = Api.getDealerInfoURL((window as any).StoreWebsiteInfo.DealerID);
        if (url){
          axios.get(url).then((response: any) => {
            Helpers.clog(url);
            Helpers.clog(response);
            this.commitOpeningHours(response.data.HourInfo);
            const closedDays = response.data.HourInfo
              .filter(function (day: any) { return day.IsClosed })
              .map(function (day: any) { return day.Day });
            this.commitDateList(closedDays);
            const dealerSitesArray = response.data.DealerSites.filter((i: any) => {
                return i.LocationType == 'Branch' || i.LocationType == 'Principal'
            });
            if (dealerSitesArray.length){
              this.$store.commit('setDealer', {
                dealerSites: dealerSitesArray, 
                lmct: dealerSitesArray[0].DealerLicenceNumber,
                region: dealerSitesArray[0].Region,
                primaryDealerCode: response.data.PrimaryDealerCode,
                state: dealerSitesArray[0].State,
                dealerMetroRural: dealerSitesArray[0].IsMetro,
                branchCode: dealerSitesArray[0].BranchCode, 
                privacyPolicy: response.data.PrivacyPolicy,
                website: 'http://' + response.data.BaseUrl
              });
              this.getVehicle();
            }
            else {
              this.$store.commit('setError', {
                  message: `Sorry, your dealer agent could not be loaded.`
              });
            }
            this.isDealerReady = true;
          })
          .catch((response) => {
            Helpers.clog(response);
            this.$store.commit('setError', {
              message: `Sorry, your dealer agent could not be loaded.`
            });
            this.isDealerReady = true;
          });
        }
      },
      getAgent(): void {
        const dealerID: number = (window as any).StoreWebsiteInfo.DealerID;
        const url: string = Api.getDealerAgentURL(dealerID);
        if (url){
          axios.get(url).then((response) => {
            Helpers.clog(url);
            Helpers.clog(response);
            this.$store.commit('setDealer', {
              storeWhyBuy: response.data.StoreWhyBuy, 
              vehicleReturnGuarantee: !!response.data.VehicleReturnGuarantee, 
              dealerClosed: response.data.DealerClosed,
              onlineOrderClosed: response.data.OnlineOrderClosed
            });
            this.$store.commit('setAgent', {
              firstName: response.data.PrimaryAgent.FirstName,
              lastName: response.data.PrimaryAgent.LastName,
              email: response.data.PrimaryAgent.Email,
              phone: response.data.PrimaryAgent.Phone,
              photoUrl: response.data.PrimaryAgent.PhotoUrl
            });
            this.$store.commit('setApp', {
              // showValueAdd: response.data.ValueAdd,
              referenceEnabled: !!response.data.ReferenceEnabled
            });
            if (!response.data.TprEnabled){
              this.$store.commit('setTpr', {
                tprEnabled: false
              });
            }
            this.$store.commit('setTpr', {
              isTprDown: !!response.data.IsTprDown
            });
            this.$store.commit('setTradeApp', {
              tradeValuationEnabled: !!response.data.TradeInEnabled
            });
          })
          .catch((response) => {
            Helpers.clog(response);
            this.$store.commit('setError', {
              message: `Sorry, your dealer could not be loaded. Please try again or select a different dealer. `
            });
          })
        } 
        else {
          this.$store.commit('setError', {
            message: `Sorry, your dealer could not be loaded. Please try again or select a different dealer. `
          });
        }
      }, 
      commitOpeningHours(data: any): void {
        const hoursList = data.map(function(day: any){
          const openingArray = day.OpeningTime.split(':');
          const closingArray = day.ClosingTime.split(':');
          if (openingArray[0] > 12){
              openingArray[0] = parseFloat(openingArray[0]) - 12;
              openingArray[2] = 'pm';
          } 
          else {
              openingArray[0] = parseFloat(openingArray[0]);
              openingArray[2] = 'am';
          }
          const opening = openingArray[0] + ':' + openingArray[1] + openingArray[2];
          if (closingArray[0] > 12){
              closingArray[0] = parseFloat(closingArray[0]) - 12;
              closingArray[2] = 'pm';
          } else {
              closingArray[0] = parseFloat(closingArray[0]);
              closingArray[2] = 'am';
          }
          const closing = closingArray[0] + ':' + closingArray[1] + closingArray[2];
          return { day: day.Day, hours: (day.IsClosed ? 'Closed' : opening + '-' + closing) };
        });
        const todayIndex = hoursList.findIndex((x: any) => x.day === Moment().format('dddd'))
        const hoursListHalf = hoursList.splice(0, todayIndex);
        const finalHoursList = hoursList.concat(hoursListHalf);
        finalHoursList[0].day = 'Today';
        this.$store.commit('setDealer', {
          openingHours: finalHoursList
        });
      },
      commitDateList(closedDays: any): void {
        const dateList = [];
        let i = 0;
        const numDays = 6 + i;
        while (i < numDays) {
          const day = Moment().add(i, 'days');
          const rawDate = Moment(day).format('YYYY-MM-DD');
          const month = Moment(day).format('MMM');
          const date = Moment(day).format('DD');
          const dayName = Moment(day).format('ddd');
          if (!closedDays.includes(Moment(day).format('dddd'))){
            dateList.push({ rawDate, date, month, dayName, active: false });
          }
          i++;
        }
        this.$store.commit('setDealer', {
          callbackDays: dateList.slice(0, 4)
        });
      }, 
      getReferenceCodeState(): void {
        const id = (window as any).StoreWebsiteInfo.DealerID;
        const url = Api.getReferenceCodeStateURL(id);
        Helpers.clog(url);
        axios.get(url).then((response: any) => {
          Helpers.clog('has active ref codes:');
          Helpers.clog(response.data);
          this.$store.commit('setApp', {hasActiveReferenceCodes: response.data});
        }).catch((response) => {
            Helpers.clog('Error checking for active ref codes');
            Helpers.clog(response);
        });
      }
    },
    mounted(){
      router.onReady(() => {
        if ('RedirectPage' == this.$route.name) return;

        const webkiosk = !!Helpers.getCookie('webkiosk');
        const tprSource: string = this.$route.query.tpr_source ? this.$route.query.tpr_source.toString() : '';
        const guid: string = Helpers.getCookie('tprguid');
        const pegaCase: string = Helpers.getCookie('tprcaseid');		
        const referrer: string = document.referrer || '';
        const slashes: string = -1 == referrer.indexOf("://") ? 'http://' : '';
        const d = this.$route.query.d || this.$route.query.di;

        this.$store.commit('setDisclaimers', disclaimerData.disclaimers);
        this.$store.commit('setApp', {
          financeDeposit: (window as any).StoreWebsiteInfo.DepositAmount || 100, 
          webkiosk: webkiosk, 
          tprSource: tprSource && !isNaN(parseInt(tprSource)) ? tprSource : null, 
          referrer: referrer ? (slashes + referrer) : ''
        });

        if (guid || pegaCase || d){
          this.$store.commit('setTpr', {
            guestRateIdentifier: guid || '', 
            pegaCase: pegaCase || '', 
            detailIdentifier: d || '', 
            hasLoadedWithTpr: !!guid
          });
        }

        const enquirySources = Helpers.getEnquirySources();
        const utmSource: any = this.$route.query.utm_source || '';
        const enqSource: any = this.$route.query.enquiry_source || '';
        const source1: string =  enquirySources[utmSource];
        const source2: string =  enquirySources[enqSource];
        if ((utmSource || enqSource) && !(source1 || source2) ){
            this.getEnquirySources(utmSource || enqSource);
        }
        else {
            const sourceId = source1 || source2 || 1;
            this.$store.commit('setApp', {
              enquirySource: {
                id: sourceId, 
                suburb: '', 
                value: utmSource || enqSource
              }
            });
            this.setEnquirySourceCookie(source1 || source2 || '');
        }

        this.getReferenceCodeState();
        this.getUserInfo();
        this.getDealer();
      });
    }
  });
