













































import Vue from 'vue';
// import { util } from '@/mixins/util.js';
export default Vue.extend({
	name: 'PrivacyModal', 
	props: {
		show: Boolean, 
		dealer: Object
	},
	methods: {
		handleCloseClick(){
			this.$store.commit('resetOpenModal');
		}
	}
})
