











































































import Vue from 'vue';
import { mapState } from 'vuex'
export default Vue.extend({
	name: 'LeavingToCompleteFinanceModal', 
	props: {
		show: {
			type: Boolean
		}
	},
	data(){
		return {
			hide: false
		}
	},
	computed: {
		...mapState({
			openModal(): any { return this.$store.state.openModal }
		})
	},
	methods: {
		handleCloseClick(): void {
			this.hide = true;
			setTimeout(() => {
				this.$store.commit('resetOpenModal');
				this.hide = false;
			}, 500);
		}, 
		handleBtnClick(btn: any): void {
			if (btn.callback && typeof btn.callback == 'function'){
				btn.callback();
			}
			else {
				this.handleCloseClick();
			}
		}
	}
});
