








































































































import Vue from 'vue';

export default Vue.extend({
	name: 'TermsModal', 
	props: {
		show: Boolean, 
		dealer: Object
	},
	data: function(): any {
        return {
            states: {
                'ACT': 'Australian Capital Territory',
                'NSW': 'State of New South Wales',
                'NT':  'Northern Territory',
                'QLD': 'State of Queensland',
                'SA':  'State of South Australia',
                'TAS': 'State of Tasmania',
                'VIC': 'State of Victoria',
                'WA':  'State of Western Australia'
            }
        }
    },
    computed: {
        formattedState(): string {
            return this.states[this.dealer.state];
        }
    },
	methods: {
		handleCloseClick(): void {
			this.$store.commit('resetOpenModal');
		}
	}
})
