










































import Vue from 'vue';
import { mapState } from 'vuex'
import { Mixins } from 'vue-property-decorator'
import Validation from '../mixins/validation';
import Helpers from '../mixins/helpers';
import Analytics from '@/mixins/analytics';
import Api from '../mixins/api';
import axios from 'axios';
import FieldText from '@/components/dumb/FieldText.vue';
export default Vue.extend({
	name: 'LoginForm', 
	mixins: [Validation, Helpers],
	props: {
		show: Boolean
	},
	components: {
		FieldText
	},
	data(){
		return {
			form: {
				email: {
					serverErrorMessage: '',
					formatErrorMessage: 'Please enter a valid email',
					value: '', 
					isLoading: false,
					isValid: false,
					isValidatedByServer: false
				},
				password: {
					showPassword: false,
					serverErrorMessage: '',
					formatErrorMessage: 'Please enter a valid password',
					value: '', 
					isLoading: false,
					isValid: false,
					isValidatedByServer: false
				}
			}
		};
	}, 
	computed: {
		...mapState({
			customer(){
				return this.$store.state.customer;
			},
			loading(): boolean {
				return this.$store.state.loading;
			},
			sessionTimeout(): number {
				return this.$store.state.app.sessionTimeout;
			},
			openModalId(): string {
				return this.$store.state.openModal.id;
			},
			loginLogged(): boolean {
				return this.$store.state.app.loginLogged;
			},
			session(): string {
				return this.$store.state.app.session;
			}
		})
	},
	methods: {
		isFieldValid(f: any, r: Array<Function>): any {
			return Validation.isFieldValid(f.value, r);
		},
		isEmail(v: string): boolean {
			return Validation.isEmail(v);
		},
		handleFieldUpdate(fieldName: string, e: any): void {
			Helpers.clog(e);
			const suffix = Analytics.getSuffix();
			Analytics.sendEvent({
				"event": "formInteraction",
				"formName" : "MyToyota Login Form" + suffix,
				"fieldName" : fieldName,
				"fieldStatus" : "Edited"
			});
		}, 
		handleEmailFocus(): void {
			// Analytics.sendFieldFocus('email');
		},
		handlePasswordFocus(): void {
			// Analytics.sendFieldFocus('password');
		},
		handlePasswordInput(){
			this.form.password.serverErrorMessage = '';
			this.form.password.isValid = !!this.form.password.value;
		},
		handleLogoutClick(): void {
			(this.$refs.loginForm as any).reset();
			this.form.email.value = '';
			this.form.email.isValid = false;
			this.form.email.isValidatedByServer = false;
			this.form.email.serverErrorMessage = '';
			this.form.password.value = '';
			this.form.password.isValid = false;
			this.form.password.isValidatedByServer = false;
			this.form.password.serverErrorMessage = '';
		},
		handleEmailSubmit(): void {
			this.form.email.isValid = this.isFieldValid(this.form.email, [this.isEmail]);
			if (this.form.email.isValid){
				this.form.email.isLoading = true;
				const idParams = {
					loginID: this.form.email.value, 
					callback: this.emailCallback
				};
				(window as any).gigya.accounts.isAvailableLoginID(idParams);
			}
		}, 
		emailCallback(response: any): void {
			this.form.email.serverErrorMessage = response.isAvailable ?  'Invalid email.' : '';
			this.form.email.isValidatedByServer = !response.isAvailable;
			this.form.email.isLoading = false;
			if (response.isAvailable){
				this.$nextTick(() => {
					(this.$refs.email as any).$refs.input.focus();
					(this.$refs.email as any).$refs.input.select();
				});
				Analytics.sendEvent({
					"event":"login",
					"loginType":"MyToyota",
					"loginStatus": "Failure"
				});
			}
			else {
				this.handlePasswordSubmit();
			}
		},
		handlePasswordSubmit(): void {
			if (this.form.email.isLoading){
				setTimeout(() => {
					this.handlePasswordSubmit();
				}, 500);
			}
			this.form.password.isLoading = true;
			// util.sendButtonClick("log in");
			(window as any).gigya.accounts.login({
				loginID: this.form.email.value, 
				password: this.form.password.value,
				sessionExpiration : this.sessionTimeout,
				callback: this.passwordSubmitCallback
			});
		},
		passwordSubmitCallback(response: any): void {
			if(!response.errorCode){
				this.form.password.serverErrorMessage = '';	
				this.form.password.isValidatedByServer = true;
				// Analytics.sendLogin();
				(window as any).gigya.accounts.getJWT({callback:this.jwtCallback})
			} 
			else {
				Analytics.sendEvent({
					"event":"login",
					"loginType":"MyToyota",
					"loginStatus": "Failure"
				});
				switch (response.errorCode){
					case 401030:
						this.form.password.serverErrorMessage = 'Old password used. ';
						this.form.password.isValidatedByServer = false;
					break;
					case 403100: 
						this.form.password.serverErrorMessage = 'Pending password change. ';
						this.form.password.isValidatedByServer = false;
					break;
					case 403042:
						this.form.password.serverErrorMessage = 'Invalid password. ';
						this.form.password.isValidatedByServer = false;
					break;
					default: 
						// util.sendError(response.errorMessage);
						this.$store.commit('setError', {
							message: response.errorMessage
						});
					break;
				}
			}
			this.form.password.isLoading = false;
		},
		jwtCallback(response: any): void {
			if(response.id_token){
				Analytics.sendEvent({
					"event":"login",
					"loginType":"MyToyota",
					"loginStatus": "Success"
				});
				Helpers.clog('setting session to id_token');
				Helpers.clog(response.id_token);
				this.$store.commit('setApp', {session:response.id_token});
				this.$emit('login');
				(window as any).gigya.accounts.getAccountInfo({callback:this.loadMeCallback});
			} 
			else {
				Analytics.sendEvent({
					"event":"login",
					"loginType":"MyToyota",
					"loginStatus": "Failure"
				});
				console.log('error. no id token.');
				// util.sendError('There was an error retrieving your session. Please try again.');
				this.$store.commit('setError', {
					message: 'There was an error retrieving your session. Please try again.'
				});
			}
		},
		loadMeCallback(response: any): void {
			if(!response.errorCode){
				this.$store.commit('setApp', {myToyotaId: response.data.myToyota.id});
				if (!this.customer.firstName){
					this.$store.commit('setCustomer', {
						firstName: response.profile.firstName
					});
				}
				if (!this.customer.email){
					this.$store.commit('setCustomer', { 
						email: response.profile.email, 
					});
				}
				const url = Api.returnLogActionUrl();
				const headers = {
					headers: {
						'my_token': this.session
					}
				};
				const postData = {
					myToyotaID : response.data.myToyota.id,
					actionType : 'login'
				}
				axios.post(url,postData, headers).then((res) => {
					Helpers.clog('login success');
					this.$emit('update');
				}).catch((error) => {
					console.log(error);
					this.$emit('update');
				});
			} 
			else {
				console.log(response);
				this.$store.commit('setError', {
					message: response.errorMessage
				});
			}
		}
	}, 
	mounted(): void {
		if (this.customer.email){
			this.form.email.value = this.customer.email;
		}
		this.$nextTick(() => {
			const e: any = this.$refs.email;
			const p: any = this.$refs.password;
			if (e.value) {
				this.form.email.value = e.value;
			}
			if (p.value) {
				this.form.password.value = p.value;
			}
			if (this.session){
				this.form.email.value = this.customer.email;
				this.form.email.isValid = true;
				this.form.email.isValidatedByServer = true;
				this.form.password.isValid = true;
				this.form.password.isValidatedByServer = true;
			}
		});
	}, 
	watch: {
		'session'(v){
			!v && this.handleLogoutClick();
		}
	}
})
