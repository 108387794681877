



















































































































import Vue from 'vue';
import { mapState } from 'vuex'

export default Vue.extend({
	name: 'AcknowledgementModal', 
	props: {
		show: Boolean, 
		dealer: Object
	},
	data: function(): any {
        return {
			isFormValid: false,
            customerAcknowledgment: false, 
			isCustomerAcknowledgmentTouched: false
        }
    },
    computed: {
		...mapState({
			openModal(): any { return this.$store.state.openModal }
		}), 
		customerAcknowledgementErrors(): Array<string> {
			return this.isCustomerAcknowledgmentTouched && !this.customerAcknowledgment ? 
			['This field is required.'] : 
			[];
		}
    },
	methods: {
		handleBtnClick(btn: any): void {
			if (btn.callback && typeof btn.callback == 'function'){
				if (btn.requiresCheck && !this.customerAcknowledgment){
					this.isCustomerAcknowledgmentTouched = true;
				}
				else {
					btn.callback();
				}
			}
			else {
				this.handleCloseClick();
			}
		},
		handleCloseClick(): void {
			this.$store.commit('resetOpenModal');
		}
	}
})
