



































import Vue from 'vue';
// import { util } from '@/mixins/util.js';
// import Analytics from '../mixins/analytics';

export default Vue.extend({
	name: 'ErrorModal', 
	props: {
		show: Boolean, 
		buttons: Array, 
		message: String,
		title: String
	},
	methods: {
		handleButtonClick(btn: any){
			btn.callback ? btn.callback() : this.handleCloseClick();
		},
		handleCloseClick(){
			this.$store.commit('resetOpenModal');
		}
	}, 
	// watch: {
	// 	'message'(){
	// 		this.message && Analytics.sendError(this.message);
	// 	}
	// },
	mounted(){
        // this.$store.commit('setLoading', { show: false });
	}
})
