import store from '@/store'
import Helpers from '@/mixins/helpers'

const Analytics = {
	sendEventFull(data: any){
		(window as any).dataLayer.push(data);
	},
	sendEvent(data: any): void {
		// if ((window as any).dataLayer){
		// 	(window as any).dataLayer.push(data);
		// }
		this.sendVirtualPageView(data);
	},
	getUniqueValuesFromObject(o: any, o2: any){
		if (typeof o2 == 'undefined') return o;
		const result: any = {};
		for (const k in o) {
			// console.log('comparing ', o[k], o2[k]);
			if (typeof o[k] != 'object'){
				// console.log('normal value');
				if (o2[k] != o[k]) {
					// console.log('is unique');
					result[k] = o[k];
				}
				// else {
					// console.log('not unique');
				// }
			}
			else {
				// console.log('object value')
				const o3 = this.getUniqueValuesFromObject(o[k], o2[k]);
				for(const prop in o3) {
					// console.log('has unique vals in object') 
					result[k] = o3;
					// result[k] = {...o[k], ...o2[k]};
					break;
				}
			}
		}
		return result;
	},
	sendVirtualPageView(data: any): void {
		if ((window as any).dataLayer){
			const d = this.getCommon();
			const d2 = this.getUniqueValuesFromObject({...d,...data}, store.state.dataLayer);
			if (d2.transaction){
				d2.transaction = d.transaction;
				if (data.transaction){
					d2.transaction = {...d2.transaction, ...data.transaction};
				}
			}
			(window as any).dataLayer.push({...d2, ...{event:data.event}});
			store.commit('setDataLayer', {...d2, ...{event:data.event}});
		}
	},
	getCommon(): any {
		const dealer = store.state.dealer;
		const customer = store.state.customer;
		const f = this.getFinanceObject();
		const t = this.getTransactionObject();
		const v = this.getVehicleObject();
		const pma = dealer.dealerSites.length ? (dealer.dealerSites[0] as any).PmaName : '';
		const misc = {
			"pageType": "omnichannel",
			"pmaName": pma, 
			"orderGuestType": customer.orderType == 1 ? 'Personal' : 'Business',
			"omniClientTitle": customer.title, 
			"omniClientYearOfBirth": customer.dob && customer.dob.includes('-') ? customer.dob.split('-')[0] : ""
		};
		const r = {...f,...t,...v, ...misc};
		return r;
	},
	getTransactionObject(): any {
		const a = store.state.app;
		const p = store.state.promoCode;
		const t1v = store.state.tradeValuationApp.car.valuationData;
		const t1f = store.state.tradeValuationApp.car.formData;
		const t2 = store.state.tradeIn;
		let tMake = '';
		let tYear = '';
		let tKms = '';
		let tAdditional = '';
		if (t1v){
			tMake = t1f.carName.replace(t1f.carYear, '');
			tYear = t1f.carYear;
			tKms = t1f.mileage;
		}
		else if (t2 && t2.hasTradeIn){
			tMake = t2.vehicleModel;
			tYear = t2.year;
			tKms = t2.km;
			tAdditional = t2.comment;
		}
		return {
			"transaction": {
				"deposit": store.getters.amountChargedToday,
				"termInMonths":	a.loanTerm * 12,
				"klmsPerYear": a.kmsPerYear,
				"tradeInMake": tMake,
				"tradeInYear": tYear,
				"tradeInKlms": tKms,
				"tradeInAddInfo": tAdditional, 
				"transactionId": store.state.app.orderNumber, 
				"referenceCode": p.Code,
				"referenceCodeStatus": ""
			}
		}
	},
	getVehicleObject(): any {
		const v = store.state.vehicle;
		return {
			"marketingID": v.hasNationalSpecial ? v.specialUniqueName : '',
			"vehicleFranchise": "Toyota",
			"vehicleModel": v.model,
			"vin": v.vin,
			"vehicleGrade": v.grade,
			"vehicleGradeVariation": v.gradeLevelDescription,
			"vehicleType": v.type, 
			"vehicleColour": v.paintName,
			"vehicleTrim": v.trimName,
			"vehicleTransmission": v.transmission,
			"vehicleDesc": v.fullDescription,
			"vehicleFactoryOptDesc": v.inclOption,
			"vehicleOnSpecial": v.hasNationalSpecial ? 'National' : v.specialItem ? 'Dealer' : '',
			"vehiclePrice": v.price,
			"vehicleMaterialCode": v.materialCode
		}
	}, 
	getFinanceObject(): any {
		const f = store.state.finance;
		const t = store.state.tpr;
		const v = store.state.vehicle;
		const c = store.state.customer;
		let rateType = '';
		// todo refactor to flows
		if (c.orderType == 2 && f.internalFinanceType == 'Campaign' && store.getters.isBusinessFinanceAvailable){
			rateType = 'Business Campaign';
		}
		else if (c.orderType == 2){
			rateType = 'Business';
		}
		else if (f.internalFinanceType == 'Campaign'){
			rateType = 'Campaign';
		}
		else if (f.isTpr) {
			rateType = 'TPR';
		}
		else if (f.internalFinanceType == 'Indicative' && v.canBeFinanced){
			rateType = 'Indicative';
		}
		return {
			"rateType": rateType,
			"hasTPRActive": store.getters.flow == 'get-tpr',
			"hasTPRApplied": !!t.guestRateIdentifier,
			"TPRNumber": t.pegaCase,

			"vehicleFinanceComparisonRate": f.comparisonRate ? f.comparisonRate + '%' : '',
			"vehicleFinanceRepayment": f.weeklyPayment ? '$' + f.weeklyPayment : '',
			"vehicleFinanceInterestRate": f.apr ? f.apr + '%' : '',
			"vehicleFinanceProduct": "Consumer Loan Access",
		};
	}, 
	getSuffix(): string {
		if (store.getters.flow == 'get-tpr'){
			return ' - No TPR';
		}
		else if (store.getters.flow == 'adjust-tpr'){
			return ' - With TPR';
		}
		else if(store.getters.flow == 'cash'){
			return ' - Cash';
		}
		else if (store.getters.flow == 'campaign'){
			return ' - Campaign';
		}
		else if (store.getters.flow == 'indicative'){
			return ' - Indicative';
		}
		else if (store.getters.flow == 'business'){
			return ' - Business Finance';
		}
		else if (store.getters.flow == 'business-campaign'){
			return ' - Business Campaign Finance';
		}
		return '';
	}
};
export default Analytics;


