
const Validation: any = {
	isEmail(value: string): any {
		const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return pattern.test(value) || "Please enter a valid email.";
	}, 
	isFormattedPhoneNumber(value: string): any {
		const v = value.replace(/\s/g, '');
		return Validation.isPhoneNumber(v);
	}, 
	isPhoneNumber(value: string): any {
		const pattern = /^([0|+[0-9]{1,5})?([0-9]{10})$/;
		return pattern.test(value) || "Please enter a valid phone number.";
	}, 
	isPostcode(value: string): any {
		return value.length === 4 ? true : 'Please enter a valid postcode.'
	},
	isOver18(value: string): any {
		const future = new Date(value);
		future.setFullYear(future.getFullYear() + 18);
		return future <= new Date() || 'You must be over 18 years of age.';
	},
	// isValidDate(d: any): boolean {
	// 	return (new Date(d)) instanceof Date && !isNaN((d as any));
	// },
	isLeapYear(year: number): boolean {
		return (year % 4 == 0) && (year % 100 != 0) || (year % 400 == 0)
	},
	validateABN(v: string): boolean {
		let valid = false;
		if (v){
			const pureAbn = v.replace(/ /g, '');
			valid = pureAbn.length === 11;
			valid = valid && /^\d+$/.test(pureAbn);
		}
		return valid;
	},
	isDate(value: string): any {
		const e = 'Please enter a valid date.';
		const pattern = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
		if (!pattern.test(value)) return e;
		const [y, m, d] = value.split('-').map(n => parseInt(n));
		return (m === (new Date(y, m - 1, d)).getMonth() + 1) || e;
	},
	isFieldValid(value: any, rules: Array<Function>): boolean {
		if (rules && rules.length){
			for (let i = 0; i != rules.length; ++i){
				const r = rules[i](value);
				if (typeof r === 'string' || (typeof r == 'boolean' && !r)){
					return false;
				}
			}
		}
		return true;
	}, 
	setFieldValid(o: any, r: Array<Function>){
		o.isValid = this.isFieldValid(o.value, r);
	}, 
	clog(v: any): void {
		if (-1 != location.hostname.indexOf('localhost') || -1 != location.hostname.indexOf('test.dealer')){
			console.log(v);
		}
	}, 
	ready(fn: Function): void {
		if (document.readyState !== 'loading'){
			fn();
		} 
		else {
			(document as any).addEventListener('DOMContentLoaded', fn);
		}
	}, 
	stripeErrors: {
		'approve_with_id': `Payment with this card cannot be authorised. Please renter or try another card.`,
		'call_issuer': `Payment was declined. Please try another card and or contact your card issuer.`,
		'card_not_supported': `This card does not support this type of payment. Please try another card.`,
		'card_velocity_exceeded': `This card has exceeded it's limit. Please try another card.`,
		'currency_not_supported': `The card does not support the specified currency.`,
		'cvc_check': `Your card's security code is incorrect.`,
		'do_not_honor': `The card was declined. Please contact the card issuer.`,
		'do_not_try_again': `The card was declined. Please contact the card issuer.`,
		'fraudulent': `The card was declined. Please contact the card issuer.`,
		'generic_decline': `The card was declined. Please contact the card issuer.`,
		'insufficient_funds': `This card has exceeded it's limit. Please try another card.`,
		'invalid_account': `The card was invalid. Please try again or contact your card issuer.`,
		'invalid_amount': `The card was unable to process the amount. Please check the limits with your card issuer.`,
		'issuer_not_available': `There was a problem processing the transaction. Please try again.`,
		'lost_card': `The card was declined. Please contact the card issuer.`,
		'new_account_information_available': `The card is invalid. Please contact the card issuer.`,
		'no_action_taken': `The card was declined. Please contact the card issuer.`,
		'not_permitted': `The payment was declined. Please contact the card issuer.`,
		'pickup_card': `The card was declined. Please contact the card issuer.`,
		'reenter_transaction': `There was a problem trying when processing the transaction. Please try again.`,
		'restricted_card': `The card was declined. Please contact the card issuer.`,
		'revocation_of_all_authorizations': `The card was declined. Please contact the card issuer.`,
		'revocation_of_authorization': `The card was declined. Please contact the card issuer.`,
		'security_violation': `The card was declined. Please contact the card issuer.`,
		'service_not_allowed': `The card was declined. Please contact the card issuer.`,
		'stolen_card': `The card was declined. Please contact the card issuer.`,
		'stop_payment_order': `The card was declined. Please contact the card issuer.`,
		'testmode_decline': `The test card was declined.`,
		'transaction_not_allowed': `The card was declined. Please contact the card issuer.`,
		'try_again_later': `There was a problem trying when processing the transaction. Please try again.`,
		'withdrawal_count_limit_exceeded': `Payment was declined. Please try another card and or contact your card issuer.`
	}
	
}
export default Validation;